@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.footerPattern {
  text-align: center;
  height: 52px;
  position: relative;

  @include mqMaxWidth(1024px) {
    display: none;
  }
}
