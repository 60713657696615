@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.features {
  margin-top: 100px;

  @include mqMaxWidth(1024px) {
    margin-top: 50px;
  }
}

.featuresContainer {
  padding: 25px 38px;

  @include mqMaxWidth(1024px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include mqMaxWidth(768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.featuresRow {
  display: table;
  margin: 0 auto;
}

.featuresColumn {
  display: table-cell;
  padding: 0 17px;
  width: 50%;
  max-width: 430px;

  @include mqMaxWidth(768px) {
    display: block;
    width: auto;
  }
}

.featuresCta {
  margin-top: 25px;
  text-align: center;

  @include mqMaxWidth(768px) {
    margin-top: 10px;
  }
}

.featuresCtaBtn {
  max-width: 307px;
  width: 100%;
  margin-right: 0;
}

.feature {
  display: table;
  margin: 45px 0;

  @include mqMaxWidth(768px) {
    margin: 38px 0;
  }

  @include mqMaxWidth(640px) {
    display: block;
    margin: 25px 0;
  }
}

.featureIcon {
  display: table-cell;
  width: 16px;
  height: 16px;
  vertical-align: middle;

  i {
    fill: #4ab675;
  }
}

.featureTitle {
  display: table-cell;
  padding-left: 20px;
  font-size: 18px;
  line-height: 21px;
  color: $titleColor;

  @include mqMaxWidth(996px) {
    font-size: 14px;
    line-height: 18px;
  }
}
