@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "./variables.scss";

.sectionTitle {
  color: $titleColor;
  font-family: $baseFontFamily;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0 0 28px;

  @include mqMaxWidth(768px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.sectionDescription {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  color: $baseTextColor;
  text-align: center;
  margin-bottom: 45px;

  a {
    color: $baseTextColor;
  }

  @include mqMaxWidth(640px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.secondaryBtn {
  background-color: $baseTextColor;

  &:hover,
  &:active {
    background-color: $secondaryColorHover;
  }
}

.actionButton {
  height: 56px;
  padding: 0 30px;
  margin: 0;
  font-size: 17px;
  letter-spacing: 0.01em;

  @include mqMaxWidth(640px) {
    width: 100%;
    height: 36px;
    font-size: 14px;
  }
}
