@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/variables.scss";
@import "@/dataroom/ui/components/Landing/dealvdr/variables.scss";

$introContainerWidth: 1268px;

/**
  Intro
 */
.intro {
  position: relative;
  padding-top: 97px;

  @include mqMaxWidth($introContainerWidth) {
    padding-top: 50px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 125%;
    top: -210px;
    transform: skewY(-7deg);
    background: $tenantDealvdrColor;

    @include mqMaxWidth(1260px) {
      top: -110px;
    }

    @include mqMaxWidth(1024px) {
      height: 85%;
    }

    @include mqMaxWidth(640px) {
      top: -40px;
      height: 125%;
    }
  }
}

.introContainer {
  width: 100%;
  max-width: 1368px;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;

  @include mqMaxWidth(640px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include mqMaxWidth(480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.introContent {
  display: table-cell;
  width: 50%;
  vertical-align: middle;
  position: relative;
  top: calc(100vw * 0.1228 / 4 - 85px);

  @include mqMaxWidth(1260px) {
    width: 45%;
    top: 20px;
  }

  @include mqMaxWidth(1024px) {
    top: 0;
    display: block;
    width: 100%;
    text-align: center;
  }
}

.introAnimation {
  display: table-cell;
  width: 100%;
  max-width: 608px;
  text-align: right;

  @include mqMaxWidth(1024px) {
    display: block;
    margin-top: 30px;
    text-align: center;
    max-width: 100%;
  }

  @include mqMaxWidth(640px) {
    display: none;
  }
}

.introAnimationContainer {
  border-radius: 5px;
  box-shadow: 0 10px 55px rgba(0, 0, 0, 0.2);
  position: relative;
  top: 13px;
  max-width: 608px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  font-size: 0;
  background: #fff;
  min-height: 380px;
}

.introTitle {
  font-family: $baseFontFamily;
  font-size: 48px;
  line-height: 52px;
  color: #fff;
  font-weight: bold;
  letter-spacing: -0.03em;
  margin: 0;

  @include mqMaxWidth(640px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.introDescription {
  width: 75%;
  font-size: 18px;
  line-height: 21px;
  color: #fff;

  p {
    margin-bottom: 30px;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }

  @include mqMaxWidth(1024px) {
    width: 100%;
  }

  @include mqMaxWidth(640px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.introPattern {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: -40px;
  z-index: -2;

  @include mqMaxWidth(1024px) {
    display: none;
  }
}

/**
  Support
 */
.support {
  position: relative;
  padding: 75px 0 200px;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 125%;
    top: -183px;
    transform: skewY(-7deg);
    background: $tenantDealvdrColor;
  }

  @include mqMaxWidth(768px) {
    padding-top: 45px;
    padding-bottom: 160px;
  }

  @include mqMaxWidth(720px) {
    padding-top: 25px;
    padding-bottom: 170px;
  }
}

.supportStamp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin: 0 auto;
  width: 110px;

  @include mqMaxWidth(768px) {
    bottom: 5px;
  }

  @include mqMaxWidth(720px) {
    bottom: 28px;
  }
}
