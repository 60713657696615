@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1310px;

  @include mqMaxWidth(480px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.large {
  max-width: 1440px;
}

.small {
  max-width: 1156px;
}

.narrow {
  padding-left: 0;
  padding-right: 0;
}
