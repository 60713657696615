@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "../variables.scss";
@import "@/dataroom/ui/components/Landing/17g5/variables.scss";

.supportContent {
  display: block;
}

.supportHeading {
  font-family: $baseFontFamily;
  font-weight: bold;
  font-size: 144px;
  line-height: 196px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #fff;

  span {
    font-family: $baseFontFamily;
    color: $tenant17g5SecondaryColor;
  }

  @include mqMaxWidth(768px) {
    font-size: 120px;
    line-height: 163px;
  }

  @include mqMaxWidth(720px) {
    font-size: 60px;
    line-height: 82px;
  }
}

.supportTitle {
  color: #fff;
  font-family: $baseFontFamily;
  font-weight: bold;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  letter-spacing: -0.02em;

  @include mqMaxWidth(720px) {
    font-size: 30px;
    line-height: 33px;
  }
}

.supportDescription {
  margin: 30px 0 0;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 21px;
  text-align: center;

  @include mqMaxWidth(720px) {
    font-size: 15px;
    line-height: 18px;
  }

  @include mqMaxWidth(640px) {
    font-size: 15px;
    line-height: 18px;
  }
}

.supportPattern {
  position: absolute;
  margin: -5px auto 0;
  left: 0;
  right: 0;
  text-align: center;

  @include mqMaxWidth(768px) {
    display: none;
  }
}
