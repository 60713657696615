@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/dataroom/ui/components/Landing/17g5/variables.scss";

.homeWrp {
  .intro:before,
  .support:before {
    background: $tenant17g5Color;
  }
}

.intro {
  margin-bottom: 150px;

  @include mqMaxWidth(1024px) {
    margin-bottom: 100px;
  }
}

.description {
  width: 85%;

  @include mqMaxWidth(1024px) {
    width: 100%;
  }
}

.homeWrp .support {
  margin-bottom: 100px;

  &:before {
    background: $tenant17g5Color;
  }

  @include mqMaxWidth(768px) {
    margin-bottom: 50px;
  }

  @include mqMaxWidth(640px) {
    margin-bottom: 0;
  }
}
