@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.learnMoreContainer {
  max-width: 790px;
  margin: 0 auto;

  @include mqMaxWidth(1024px) {
    padding-bottom: 50px;
  }

  @include mqMaxWidth(768px) {
    padding-bottom: 30px;
  }

  @include mqMaxWidth(640px) {
    padding-bottom: 0;
  }
}

.learnMoreDescription {
  padding: 0 20px;
}

.learnMoreButtons {
  text-align: center;
  margin-bottom: 88px;
  padding-bottom: 0;

  @include mqMaxWidth(996px) {
    margin-bottom: 0;
    padding-bottom: 36px;
  }
}
