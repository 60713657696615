@import "../base.scss";

.pageContainer {
  display: block;
  width: 100%;
  background-color: #fff;
}

.mobileMenu {
  background: linear-gradient(214.31deg, #3d906d 20.42%, #2b656d 79.79%);
}
